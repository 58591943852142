import React from "react";
import { Row, Col, Typography } from "antd";
import "../App.css";
import AuthPanel from "./AuthPanel";
import SubscriptionForm from "./Subscription";
import { CheckCircleOutlined } from '@ant-design/icons';

const { Paragraph, Text } = Typography;

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="hero-container">
        <h1 className="hero-title">
          Your <span className="wave">AI-Autopilot</span> for Recruitment
        </h1>
        <ul className="hero-description" style={{ fontSize: '25px', listStyleType: 'none', padding: 10, lineHeight: '1.8' }}>
          <li>
            Not another ATS – a candidate-centric platform that keeps Recruiters, Hiring Managers, and Candidates in Sync for 3.5X Faster and Transparent Hiring.
          </li>
        </ul>
        {/* Add 14-day free trial and no credit card required */}
        <Row 
  gutter={[24, 16]} 
  style={{ marginTop: 30, textAlign: 'center', marginBottom: 20 }} 
  justify="center"
>
  {/* 14-day Free Trial */}
  <Col xs={24} sm={12} className="trial-column">
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CheckCircleOutlined style={{ color: 'green', marginRight: 8, fontSize: '20px' }} />
      <Text style={{ fontSize: '18px', fontWeight: '600', textAlign: 'center' }}>14-day Free Trial</Text>
    </div>
  </Col>

  {/* No Credit Card Required */}
  <Col xs={24} sm={12} className="trial-column">
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CheckCircleOutlined style={{ color: 'green', marginRight: 8, fontSize: '20px' }} />
      <Text style={{ fontSize: '18px', fontWeight: '600', textAlign: 'center' }}>No Credit Card Required</Text>
    </div>
  </Col>
</Row>

        <AuthPanel />
        <SubscriptionForm />
      </div>
    </div>
  );
  }  

export default HeroSection;
