import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Layout, Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Popup from "./popup";
import CalendlyPopup from "./Calendlypopup";
import "../App.css";
import { useUser } from "../context";
import { ROUTES, TOKEN_KEY } from "./common";

const { Header } = Layout;

const Navbar = () => {
  const navigate = useNavigate();
  const { user, getMe } = useUser();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false); // State to control Popup visibility
  const [calendlyVisible, setCalendlyVisible] = useState(false);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
  };
  const showCalendly = () => {
    setCalendlyVisible(true);
  };

  const hideCalendly = () => {
    setCalendlyVisible(false);
  };

  const loadProfile = async () => {
    const localToken = localStorage.getItem(TOKEN_KEY);
    if (!user?.token || !localToken) await getMe(true);
    else navigate(ROUTES.DASHBOARD);
  };
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      setDrawerVisible(false); // Close the drawer in mobile view
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  
  return (
    <Header className="navbar">
      <div className="logo">
        <img src="/chronoflow.svg" alt="Logo" />
        <span className="logo-text">Chronoflow</span>

      </div>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["1"]}
        className="menu"
      >
        <Menu.Item key="1">
          <Link to={ROUTES.DASHBOARD}>About</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <div onClick={loadProfile}>Dashboard</div>
        </Menu.Item>
        <Menu.Item key="3">
          <a href="#pricing-cards">Pricing</a>
        </Menu.Item>
        <Menu.Item key="4">
          <Link onClick={showModal}>Contact</Link>
          <Popup visible={modalVisible} onClose={handleClose} />
        </Menu.Item>
        <Menu.Item key="5">
          <a href="#faq">FAQs</a>
        </Menu.Item>
      </Menu>
      <Button
        type="primary"
        className="book-demo-button"
        onClick={showCalendly}
      >
        Book Demo
      </Button>
      <CalendlyPopup isVisible={calendlyVisible} onClose={hideCalendly} />
      <MenuOutlined className="mobile-menu-icon" onClick={toggleDrawer} />
      <Drawer title="Menu" placement="right" visible={drawerVisible} onClose={toggleDrawer}>
        <Menu mode="vertical">
          <Menu.Item key="1">
            <Link to="/">About</Link>
          </Menu.Item>
          <Menu.Item key="2">
          <div onClick={loadProfile}>Dashboard</div>
          </Menu.Item>
          <Menu.Item key="3" onClick={() => scrollToSection("pricing-cards")}>
            <a href="#pricing-cards">Pricing</a>
          </Menu.Item>
          <Menu.Item key="4">
          <Link onClick={showModal}>Contact</Link>
          <Popup visible={modalVisible} onClose={handleClose} />
          </Menu.Item>
          <Menu.Item key="5" onClick={() => scrollToSection("pricing-cards")}>
            <a href="#faq">FAQs</a>
          </Menu.Item>
          <Menu.Item key="6">
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={showCalendly}
            >
              Book Demo
            </Button>
            <CalendlyPopup isVisible={calendlyVisible} onClose={hideCalendly} />

          </Menu.Item>
        </Menu>
      </Drawer>
    </Header>
  );
};

export default Navbar;
