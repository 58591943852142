import React, { useState } from 'react';
import '../App.css';

const VideoSection = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleVideoSize = () => {
    setIsExpanded(!isExpanded);
  };

  const videoUrl = "https://www.youtube.com/embed/vgDmxVzvBAw"; // Correct embed link

  return (
    <section className="video-section">
      <div 
        className="video-container" 
        onClick={toggleVideoSize} 
        style={{ cursor: 'pointer' }}
      >
        <iframe
          width={isExpanded ? '1000' : '640'}
          height={isExpanded ? '562' : '360'}
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
};

export default VideoSection;
